export default {
  login: {
    title: '用戶登錄',
    accountPlaceholder:'賬號',
    passwordPlaceholder:'密碼',
    btnText: '登錄'
  },
  home:{
      news:"最新消息",
      outbound:"境外游",
      inbound:"境内游",
      mybooking:"我的預定",
      commission:"佣金",
      tourismtermsPage:"條款",
      contractus:"聯係我們",
      logout:"退出",
      login:"登錄",
      select:"選擇"
  },
  commission:{
    refresh:"刷新",
    tourNo:"行程編號",
    tourTitle:"行程標題",
    adultPrice:"成人價格",
    adultCommission:"成人佣金",
    childWithBedPrice:"孩童占床價格",
    childWithBedCommission:"孩童占床佣金",
    childWithoutBedPrice:"孩童不占床價格",
    childWithoutBedCommission:"孩童不占床佣金",
    comprehensiveServiceFee:"綜合服務費",
    compulsoryProgramFee:"指定自費項目",
    simpleSup:"單間差",
    select:"選擇",
    outboundTour:"境外游",
    inboundTour:"境内游",

  },
  contractUs:{
    contractUs:"聯係我們",
    generalEnquiries:"一般查詢",
    generalEnquiriesAndBooking:"一般查詢和預訂",
    businessHour:"營業時間",
    usEastCoastTime:"美國東岸時間",
    orPleaseContractOurPreferredAgents:"或者請聯係我們的首席代理商",
    scanQRCode:"掃描二維碼來添加我們的客服微信",
    followUS:"關注我們"
},
  inboundTour:{
    search:"搜索",
    englishNameOrChineseName:"英文名稱/中文名稱",
    tourCode:"行程編號",
    tourName:"行程名稱",
    countryAndTour:"國家和行程",
    duration:"周期",
    priceForm:"價格",
    startCity:"起始城市",
    endCity:"結束城市",
    view:"查看",
    book:"預定",
    ViewDetail:"詳情",
    back:"返回",
    day:"天",
    daysOfSchedule:"行程天数",
    priceList:"價格列表",
    itineraryFeatures:"Itinerary Features",
    departureDate:"行程日期",
    remark:"備注",
    pickUpPoint:"Pick Up Point",
    downloadPoster:"下載海報",
    noticeToAttendees:"注意事項"
  },
  outboundTour:{
    search:"搜索",
    englishNameOrChineseName:"英文名稱/中文名稱",
    tourCode:"行程編號",
    tourName:"行程名稱",
    countryAndTour:"國家和行程",
    duration:"周期",
    priceForm:"價格",
    startCity:"起始城市",
    endCity:"結束城市",
    view:"查看",
    book:"預定",
    ViewDetail:"詳情",
    back:"返回",
    day:"天",
    daysOfSchedule:"行程天数",
    priceList:"價格列表",
    itineraryFeatures:"行程特色",
    departureDate:"行程日期",
    remark:"備注",
    pickUpPoint:"接送点",
    downloadPoster:"下載海報",
    noticeToAttendees:"注意事項",
    dateAndPrice:"日期和價格",
    scheduling:"行程"
  },
  news:{
    latestNews:"最新消息",
    detail:"详情介绍",
    topNews:"置頂消息"
  },
  newsInfo:{
    back:"返回"
  },
  mybooking:{
    bookingID:"訂單ID",
    bookingRefNo:"訂單參考號",
    tourName:"行程名稱",
    tourDate:"行程日期",
    search:"搜索",
    export:"導出",
    pax:"旅客數量",
    paymentStatus:"支付狀態",
    passengerName:"旅客",
    yourRef:"Ref No",
    consultant:"顧問",
    bookingDate:"預訂日期",
    status:"狀態",
    processing:"處理中",
    confirmed:"已確認",
    cancelled:"已取消",
    view:"查看",
    voucher:"确认书",
    invoice:"账单",
    back:"返回"
  },
  voucher:{
    bookingNo:"訂單編號",
    invoiceNo:"訂單清單",
    tourCode:"行程碼",
    agentName:"代理名稱",
    tourName:"行程名稱",
    tourDate:"行程日期",
    consultant:"顧問",
    refNo:"Ref No.",
    travellers:"旅客",
    name:"姓名",
    gender:"性別",
    passengerInfo:"旅客信息",
    adult:"成人",
    childWithBed:"孩童占床",
    childWithoutBed:"孩童不占床",
    arrivalDetails:"抵達信息",
    departureDetails:"起程信息",
    noOfRooms:"房間數量",
    singleRoom:"單人間",
    twinRoom:"雙人間",
    twinRoomExtraBed:"雙人間額加床",
    tippingPrepaid:"預付小費",
    compulsoryProgramPrepaid:"指定項目費用預付",
    yes:"是",
    no:"否",
    extraItemDescription:"額外項信息",
    pickupPoint:"接人地點",
    remark:"備注",
    tourGuide:"行程指南",
    scheduling:"行程安排",
    downloadPDF:"下載 PDF"
  },

  personalCenter:{
    changePassword:"修改密碼",
    newPassword:"新密碼",
    enterYourPassword:"輸入密碼",
    confirm:"確認",
    submit:"提交"
  },
  tourismTerms:{
      selectArea:"選擇地區"
  },
  bookingInvoice:{
    invoiceDate:"費用清單日期",
    agentName:"代理名稱",
    consultant:"顧問",
    invoiceNo:"清單編號",
    refNo:"Ref No",
    bookingID:"訂單ID",
    name:"名稱",
    description:"描述",
    unitPrice:"單價",
    qty:"數量",
    subtotal:"總額",
    total:"總數",
    cancelled:"已取消",
    termAndCondition:"條款和條件",
    downloadPDF:"下載PDF"
  },
  bookingSummary:{
    bookingSummary:"訂單概要",
    tourCode:"行程編號",
    tourName:"行程名稱",
    travelDate:"周期",
    tourDate:"行程日期",
    tourLanguage:"語言",
    consultant:"顧問",
    bookingRefNo:"訂單Ref No",
    passengers:"乘客",
    numberOfPassengers:"乘客數量",
    numberOfRooms:"房間數量",
    pickupPointDetails:"接送點詳細信息",
    arrivalDetail:"到達目的地詳細信息",
    departureDetails:"起程詳細信息",
    preHotel:"旅游前酒店信息",
    postHotel:"旅游后酒店信息",
    bookingID:"訂單ID",
    tippingPrepaid:"預付小費",
    compulsoryPrepaid:"預付自費項目",
    specialRequest:"額外要求",
    pickUpPoint:"接送點",
    downloadPDF:"下載PDF"
  },
  bookingTour:{
    selectTour:"行程配置",
    dateAndLanguage:"日期和語言",
    selectATourDate:"選擇一個行程日期",
    selectATourLanguage:"選擇一個行程語言",
    consultant:"顧問",
    yourBookingReferNo:"預定Refer No",
    next:"下一步",
    passenger:"旅客",
    passengerDetails:"旅客信息",
    lastName:"姓",
    firstName:"名",
    birthday:"出生日期",
    selectDate:"選擇日期",
    gender:"性別",
    nationality:"國籍",
    pleaseSelect:"請選擇",
    PR:"綠卡",
    passportNo:"護照號碼",
    pleaseInput:"請輸入",
    speaking:"語言",
    passengerType:"乘客類型",
    needbed:"需要占床",
    back:"返回",
    roomConfigure:"房間配置",
    singleRoom:"單人間",
    twinRoom:"雙人間",
    twinRoomExtraBed:"雙人間加床",
    numberOfRooms:"房間數量",
    serviceRequest:"服務請求",
    transferServices:"運轉服務",
    arrivalDetails:"到達點信息",
    flightNo:"飛行編號",
    noneSelected:"沒有選擇",
    departureDetails:"起程信息",
    prePostTourAccommodation:"旅游前後住宿",
    preTourAccommodation:"旅游前住宿",
    postTourAccommodation:"旅游后住宿",
    specialRequest:"特殊要求",
    pleaseTypeYourSpecialRequest:"請輸入您的特殊要求",
    isCompulsoryProgramPrepaid:"是否預付指定預付項目",
    isTippingprepaid:"是否預付小費",
    roomType:"-- 房價類型 --",
    night:"-- 選擇几晚 --",
    oneNight:"一晚",
    twoNight:"兩晚",
    threeNight:"三晚",
    fourNight:"四晚",
    fiveNight:"五晚",
    single:"單人閒",
    double:"兩人閒",
    twin:"雙人閒",
    triple:"家庭房",
    submit:"提交"
  }

}