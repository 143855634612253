<template>
    <div>
        <div class="location-group">
            <el-tabs class="commission-tabs" v-model="tabActiveName">
                <el-tab-pane :label="$t('commission.outboundTour')" name="outbound">
                    <div>
                        <el-radio-group v-model="selectedOutboundCategory" @change="selectedOutboundCategoryChange"  size="small">
                            <div class="location-container">
                                <el-radio-button v-for="(item,index) in outboundTourCatagories" :key="index"  class="location-item" :label="item.value">{{ $i18n.locale=='en'?item.englishName:item.chineseName }}</el-radio-button>
                                
                            </div>
                            
                        </el-radio-group>
                        <el-divider></el-divider>
                        <div class="location-container">
                            <el-radio-group v-model="selectedOutboundType" @change="selectedOutboundTypeChange"  size="small">
                                <el-radio-button v-for="(item,index) in tourTypies" :key="index"  class="location-item" :label="item.value" >{{ $i18n.locale=='en'?item.englishName:item.chineseName }}</el-radio-button>
                            </el-radio-group>
                        </div>
                        <el-divider></el-divider>
                        <div class="location-container">
                            <el-radio-group v-model="selectedTag" @change="selectedTagChange"  size="small">
                                <el-radio-button v-for="(item,index) in allTags" :key="index"  class="location-item" :label="item.id" >{{ $i18n.locale=='en'?item.englishName:item.name }}</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div style="margin: 1rem;text-align: right;">
                            <el-button type="primary" size="mini" @click="getOutboundTableData(1)">{{$t('commission.refresh')}}</el-button>
                        </div>
                        <div class="table-container">
                            <div class="table-content">
                                <el-table
                                    v-loading="loadingOutboundTable"
                                    :data="commissionOutboundTableData"
                                    border
                                    height="60vh"
                                    style="width: 100%;min-height: 30vh;max-height: 60vh;">
                                    <el-table-column
                                        :label="$t('commission.tourNo')"
                                        width="150">
                                        <template slot-scope="scope">
                                            <label>{{ scope.row.trip.tourCode }}</label>                                         
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('commission.tourTitle')"
                                        width="150">
                                        <template slot-scope="scope">
                                            <label>{{ scope.row.trip.tourName }}</label>                                         
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="adultPrice"
                                        :label="$t('commission.adultPrice')"
                                        width="120">
                                    </el-table-column>
                                    <el-table-column
                                        prop="adultCommission"
                                        :label="$t('commission.adultCommission')"
                                        width="150">
                                    </el-table-column>
                                    <el-table-column
                                        prop="childWithBedPrice"
                                        :label="$t('commission.childWithBedPrice')"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="childWithBedCommission"
                                        :label="$t('commission.childWithBedCommission')"
                                        width="220">
                                    </el-table-column>
                                    <el-table-column
                                        prop="childWithoutBedPrice"
                                        :label="$t('commission.childWithoutBedPrice')"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="childWithoutBedCommission"
                                        :label="$t('commission.childWithoutBedCommission')"
                                        width="200">
                                    </el-table-column>

                                    <!--
                                    <el-table-column
                                        prop="infantWithBedPrice"
                                        label="Infant With Bed Gross"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="infantWithBedCommission"
                                        label="Infant With Bed Comm Comm"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="infantWithoutBedPrice"
                                        label="Infant No Bed Gross"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="infantWithoutBedCommission"
                                        label="Infant No Bed Comm"
                                        width="150">
                                    </el-table-column>
                                -->

                                    <el-table-column
                                        :label="$t('commission.comprehensiveServiceFee')"
                                        width="190">
                                        <template slot-scope="scope">
                                            <label>{{ scope.row.comprehensiveServiceFee }}</label>                                         
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('commission.compulsoryProgramFee')"
                                        width="200">
                                        <template slot-scope="scope">
                                            <label>{{ scope.row.compulsoryProgramFee }}</label>                                         
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('commission.simpleSup')"
                                        width="110">
                                        <template slot-scope="scope">
                                            <label>{{ scope.row.simpleSup }}</label>                                         
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination
                                    @size-change="handleOutboundCommissionPageSizeChange"
                                    @current-change="handleOutboundCommissionCurrentPageChange"
                                    :current-page="currentOutboundCommissionPage"
                                    :page-size="commissionOutboundPageSize"
                                    :page-sizes="[10, 50, 100, 200]"
                                    :total="commissionOutboundTotalCount">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('commission.inboundTour')" name="inbound">
                    <div>
                        <el-radio-group v-model="selectedInboundCategory" @change="selectedInboundCategoryChange"  size="small">
                            <div class="location-container">
                                <el-radio-button v-for="(item,index) in inboundTourCatagories" :key="index"  class="location-item" :label="item.value">{{ $i18n.locale=='en'?item.englishName:item.chineseName }}</el-radio-button>
                                
                            </div>
                            
                        </el-radio-group>
                        <div style="margin: 1rem;text-align: right;">
                            <el-button type="primary" size="mini" @click="getInboundTableData(1)">{{$t('commission.select')}}</el-button>
                        </div>
                        <div class="table-container">
                            <div class="table-content">
                                <el-table
                                    v-loading="loadingInboundTable"
                                    :data="commissionInboundTableData"
                                    border
                                    height="60vh"
                                    style="width: 100%;min-height: 30vh;max-height: 60vh;">
                                    <el-table-column
                                        :label="$t('commission.tourNo')"
                                        width="150">
                                        <template slot-scope="scope">
                                            <label>{{ scope.row.trip.tourCode }}</label>                                         
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('commission.tourTitle')"
                                        width="150">
                                        <template slot-scope="scope">
                                            <label>{{ scope.row.trip.tourName }}</label>                                         
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="adultPrice"
                                        :label="$t('commission.adultPrice')"
                                        width="120">
                                    </el-table-column>
                                    <el-table-column
                                        prop="adultCommission"
                                        :label="$t('commission.adultCommission')"
                                        width="150">
                                    </el-table-column>
                                    <el-table-column
                                        prop="childWithBedPrice"
                                        :label="$t('commission.childWithBedPrice')"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="childWithBedCommission"
                                        :label="$t('commission.childWithBedPrice')"
                                        width="220">
                                    </el-table-column>
                                    <el-table-column
                                        prop="childWithoutBedPrice"
                                        :label="$t('commission.childWithoutBedPrice')"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="childWithoutBedCommission"
                                        :label="$t('commission.childWithoutBedCommission')"
                                        width="200">
                                    </el-table-column>

                                    <!--
                                    <el-table-column
                                        prop="infantWithBedPrice"
                                        label="Infant With Bed Gross"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="infantWithBedCommission"
                                        label="Infant With Bed Comm Comm"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="infantWithoutBedPrice"
                                        label="Infant No Bed Gross"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="infantWithoutBedCommission"
                                        label="Infant No Bed Comm"
                                        width="150">
                                    </el-table-column>
                                    -->

                                    <el-table-column
                                        :label="$t('commission.comprehensiveServiceFee')"
                                        width="190">
                                        <template slot-scope="scope">
                                            <label>{{ scope.row.comprehensiveServiceFee }}</label>                                         
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('commission.compulsoryProgramFee')"
                                        width="200">
                                        <template slot-scope="scope">
                                            <label>{{ scope.row.compulsoryProgramFee }}</label>                                         
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('commission.simpleSup')"
                                        width="110">
                                        <template slot-scope="scope">
                                            <label>{{ scope.row.simpleSup }}</label>                                         
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination
                                    class="table-content"
                                    background
                                    layout="prev, pager, next"
                                    @size-change="handleInboundCommissionPageSizeChange"
                                    @current-change="handleInboundCommissionCurrentPageChange"
                                    :current-page="currentInboundCommissionPage"
                                    :page-size="commissionInboundPageSize"
                                    :page-sizes="[10, 50, 100, 200]"
                                    :total="commissionInboundTotalCount">
                                </el-pagination>
                            </div>
                        </div>
                    </div>

                </el-tab-pane>
               
               
            </el-tabs>

           
        </div>
        <FooterPage></FooterPage> 
    </div>
    
</template>
  
<script>
import FooterPage from '../../../components/footer.vue';
export default {
    components:{FooterPage},
    name: 'commission-page',
    props:{
            Area:null
    },
    watch:{

        Area:function(newValue,oldValue){
            console.log(111111111,oldValue);
            this.area = newValue;
            this.getTours(1);
            this.getRotationCharts();
            
        }
    },
    data(){
        return {
           
            commissionInboundTableData:[],
            commissionOutboundTableData:[],

            tabActiveName:"outbound",
            commissionInboundPageSize:10,
            commissionOutboundPageSize:10,

            currentInboundCommissionPage:1,
            currentOutboundCommissionPage:1,

            commissionInboundTotalCount:0,
            commissionOutboundTotalCount:0,

            area:null,
            selectedInboundCategory:"",
            selectedInboundType:"",
            selectedOutboundCategory:"",
            selectedOutboundType:"",
            inboundTourCatagories:[],
            outboundTourCatagories:[],

            loadingInboundTable:false,
            loadingOutboundTable:false,

            tourTypies:[],
            selectedTag:0,
            allTags:[]
        }
    },
    mounted(){
        this.getInboundTourCatagories();
        this.getOutboundTourCatagories();
        this.getTourTypies();
        this.getAllTags();
        this.getInboundTableData(1);
        this.getOutboundTableData(1);
    },
    methods:{
        getAllTags(){
            let that = this;
            this.$httpservice.get('api/tag/List',{pageIndex:0,pageSize:0,show:true}).then(result => {
                that.loadingTable=false;
                if(result.status==200){
                    if(result.body.code=='200'){
                        that.allTags = result.body.data;
                    }else{
                        that.$message({
                            message: 'Operation Failed',
                            type: 'warning'
                        });
                    }
                }
            });
        },
        selectedTagChange(e){
            console.log(e);
            this.getInboundTableData(1);
            this.getOutboundTableData(1);
        },
        getTourTypies(){
            let that = this;
            this.$httpservice.get('api/common/GetTourType',).then(result => {
                if(result.status==200){
                if(result.body.code=='200'){
                    that.tourTypies = result.body.data;
                }else{
                    this.$message({
                        message: '获取行程分类信息失败',
                        type: 'warning'
                    });
                }
                }
            });
        },
        getInboundTourCatagories(){
            let that = this;
            this.$httpservice.get('api/common/GetTourCatagory',{inbound:true}).then(result => {
            if(result.status==200){
                if(result.body.code=='200'){
                    that.inboundTourCatagories = result.body.data;
                }else{
                    this.$message({
                            message: 'Get Tour Catagories Failed',
                            type: 'warning'
                    });
                }
            }
            });
        },
        getOutboundTourCatagories(){
            let that = this;
            this.$httpservice.get('api/common/GetTourCatagory',{inbound:false}).then(result => {
            if(result.status==200){
                if(result.body.code=='200'){
                    that.outboundTourCatagories = result.body.data;
                }else{
                    this.$message({
                            message: 'Get Tour Catagories Failed',
                            type: 'warning'
                    });
                }
            }
            });
        },
        getInboundTableData(pageIndex){
            let that = this;
            that.loadingInboundTable=true;
            console.log(this.$account.area);
            this.$httpservice.get('api/trip/GetCommissionTable',{pageIndex:pageIndex,pageSize:this.commissionInboundPageSize,area:this.$account?.area,category:this.selectedInboundCategory,type:this.selectedInboundType,inbound:true,tagId:this.selectedTag}).then(result => {
                that.loadingInboundTable=false;
                if(result.status==200){
                    if(result.body.code=='200'){
                        that.commissionInboundTableData = result.body.data;
                        that.commissionInboundTotalCount = result.body.totalCount;
                    }else{
                        that.$message({
                            message: 'Operation Failed',
                            type: 'warning'
                        });
                    }
                }
            });
        },

        getOutboundTableData(pageIndex){
            let that = this;
            that.loadingOutboundTable = true;
            this.$httpservice.get('api/trip/GetCommissionTable',{pageIndex:pageIndex,pageSize:this.commissionOutboundPageSize,area:this.$account?.area,category:this.selectedOutboundCategory,type:this.selectedOutboundType,inbound:false,tagId:this.selectedTag}).then(result => {
                that.loadingOutboundTable = false;
                if(result.status==200){
                    if(result.body.code=='200'){
                        that.commissionOutboundTableData = result.body.data;
                        that.commissionOutboundTotalCount = result.body.totalCount;
                    }else{
                        that.$message({
                            message: 'Operation Failed',
                            type: 'warning'
                        });
                    }
                }
            });
        },

        handleInboundCommissionPageSizeChange(e){
            this.commissionInboundPageSize=e;
            this.getInboundTableData(1);
        },

        handleInboundCommissionCurrentPageChange(e){
            console.log(e);
            this.currentInboundCommissionPage =e;
            this.getInboundTableData(e);
        },

        handleOutboundCommissionPageSizeChange(e){
            this.commissionOutboundPageSize=e;
            this.getOutboundTableData(1);
        },

        handleOutboundCommissionCurrentPageChange(e){
            console.log(e);
            this.currentOutboundCommissionPage=e;
            this.getOutboundTableData(e);
        },

        selectedInboundCategoryChange(e){
            this.selectedInboundCategory=e;
            this.getInboundTableData(1);
        },
        selectedOutboundCategoryChange(e){
            this.selectedOutboundCategory=e;
            this.getOutboundTableData(1);
        },
        selectedOutboundTypeChange(e){
            console.log(e);
            this.getOutboundTableData(1);
        }
    }
   
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@media screen and (min-aspect-ratio:16/16) and (min-width:1000px){

    .location-group{
        padding: 1.5rem 8.6rem 1.5rem 8.6rem;
        .location-container{
            display: flex;
            flex-wrap:wrap;
            .location-item{
                margin-right: 1rem;
                margin-top: 1rem;
            }
        }
    }

}

@media screen and (max-width:1000px){
    .location-group{
        padding: 1rem;
        .location-container{
            display: flex;
            flex-wrap:wrap;
            .location-item{
                margin-right: 1rem;
                margin-top: 1rem;
            }
        }
    }

}

@media screen and (max-aspect-ratio:16/16) {
    .location-group{
        padding: 1rem;
        .location-container{
            display: flex;
            flex-wrap:wrap;
            .location-item{
                margin-right: 1rem;
                margin-top: 1rem;
            }
        }
    }

}


.table-container{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.table-content{
   width: 100%;
   text-align: center;
   margin-top: 1rem;
}

</style>
  